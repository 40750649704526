$(document).ready(function () {

    /* Toggle Password Visibility */
    const pwd_icons = document.querySelectorAll('.password-icon');
    pwd_icons.forEach(function (item) {
        item.addEventListener('click', function () {
            let pwd_input = item.previousElementSibling;
            let pwd_input_type = item.previousElementSibling.getAttribute('type');
            if(pwd_input_type === 'password') {
                pwd_input.setAttribute('type', 'text');
                item.classList.replace('mdi-eye-off', 'mdi-eye');
            }else{
                pwd_input.setAttribute('type', 'password');
                item.classList.replace('mdi-eye', 'mdi-eye-off');
            }
        });
    });

});
