$(document).ready(function () {
    const hamburger = document.getElementById("top-nav-hamburger");
    const expandedTopNavbar = document.getElementById("top-nav-links-wrapper");

    hamburger.addEventListener("click", function (){
        if (expandedTopNavbar.style.maxHeight === ''){
            expandedTopNavbar.style.maxHeight = '500' + 'px';
        } else {
            expandedTopNavbar.style.maxHeight = '';
        }
    });
});






